import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const cn = (...inputs) => {
	return twMerge(clsx(inputs));
};

export const getCookie = (name) => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) return parts.pop().split(";").shift();
};

export const base64ToSvg = (base64) => {
	const decodedSvg = atob(base64.replace("data:image/svg+xml;base64,", ""));
	const parser = new DOMParser();
	const svg = parser.parseFromString(decodedSvg, "image/svg+xml");
	return svg.documentElement;
};

export const deepMerge = (target, source) => {
	const result = { ...target };

	for (const key of Object.keys(source)) {
		if (
			typeof source[key] === "object" &&
			source[key] !== null &&
			!Array.isArray(source[key])
		) {
			if (typeof target[key] === "object" && target[key] !== null) {
				result[key] = deepMerge(target[key], source[key]);
			} else {
				result[key] = deepMerge({}, source[key]);
			}
		} else if (typeof source[key] === "function") {
			// If the value is a function, directly assign it
			result[key] = source[key];
		} else if (Array.isArray(source[key])) {
			// If the value is an array, create a new array and deep merge each item
			result[key] = source[key].map((item) =>
				typeof item === "object" && item !== null ? deepMerge({}, item) : item,
			);
		} else {
			// For all other types, use structuredClone
			result[key] = structuredClone(source[key]);
		}
	}

	return result;
};

export const debounce = (func, wait) => {
	let timeout;
	return function (...args) {
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			func.apply(this, args);
		}, wait);
	};
};

export const slugify = (str) => {
	return String(str)
		.normalize("NFKD") // split accented characters into their base characters and diacritical marks
		.replace(/[\u0300-\u036f]/g, "") // remove all the accents, which happen to be all in the \u03xx UNICODE block.
		.trim() // trim leading or trailing whitespace
		.toLowerCase() // convert to lowercase
		.replace(/[^a-z0-9 -]/g, "") // remove non-alphanumeric characters
		.replace(/\s+/g, "-") // replace spaces with hyphens
		.replace(/-+/g, "-"); // remove consecutive hyphens
};

export const isMobile = () => {
	const conditions = [
		// Touch capability
		"ontouchstart" in window ||
			navigator.maxTouchPoints > 0 ||
			navigator.msMaxTouchPoints > 0,
		// Screen size
		window.screen.width < 768 || window.screen.height < 768,
		// Pointer characteristics
		window.matchMedia("(pointer: coarse)").matches,
		window.matchMedia("(hover: none)").matches,
		// Device orientation capability
		"orientation" in window,
		// User agent string check (as a fallback, less reliable)
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent,
		),
		// Connection type (often slower on mobile)
		navigator.connection &&
			["slow-2g", "2g", "3g"].includes(navigator.connection.effectiveType),
	];

	return conditions.some((condition) => condition === true);
};

export const isTablet = () => {
	return (
		window.screen.width >= 768 && window.screen.width <= 1024 && isMobile()
	);
};

export const memoize = (fn) => {
	const cache = new Map();
	return (...args) => {
		const key = JSON.stringify(args);
		if (cache.has(key)) {
			return cache.get(key);
		}
		const result = fn(...args);
		cache.set(key, result);
		return result;
	};
};
